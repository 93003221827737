import { AppBar, Box, Button, Stack, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import { logo } from "../../../assets/image";
import Iconify from "../../../components/Iconify";
import Image from "../../../components/Image";
import { IconButtonAnimate } from "../../../components/animate";
import { HEADER } from "../../../config";
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
import { PATH_DASHBOARD } from "../../../routes/paths";
import cssStyles from "../../../utils/cssStyles";

type RootStyleProps = {
  isOffset: boolean;
};

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isOffset",
})<RootStyleProps>(({ isOffset, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: "100%",
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
  },
}));

type Props = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardHeader({ onOpenSidebar }: Props) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT);

  const isDesktop = useResponsive("up", "lg");

  const menuItems = [
    { label: "제품", path: PATH_DASHBOARD.article.products },
    { label: "설치사례", path: PATH_DASHBOARD.blog.posts },
  ];

  return (
    <RootStyle isOffset={isOffset}>
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
          maxWidth: "xl",
          alignSelf: "center",
          width: "100%",
        }}
      >
        {/* {isDesktop && <Logo sx={{ mr: 2.5 }} /> } */}
        {isDesktop && (
          <RouterLink to="/">
            <Image alt="logo" src={logo} sx={{ height: 40 }} />
          </RouterLink>
        )}
        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: "text.primary" }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}
        <Box sx={{ flexGrow: 1 }} />
        {isDesktop && (
          <Stack direction="row" alignItems="center" spacing={3}>
            {menuItems.map((item) => (
              <Button key={item.label} to={item.path} variant="text" component={RouterLink}>
                <Typography variant="subtitle1" color="#333">
                  {item.label}
                </Typography>
              </Button>
            ))}
          </Stack>
        )}
      </Toolbar>
    </RootStyle>
  );
}
