import { SettingsValueProps } from "./@types/settings";

export const FIREBASE_API = {
  apiKey: "AIzaSyDSjmsHrqNjnpPOJK7kI9T59EgXhw8BoL8",
  authDomain: "hsq-website.firebaseapp.com",
  projectId: "hsq-website",
  storageBucket: "hsq-website.appspot.com",
  messagingSenderId: "685535495936",
  appId: "1:685535495936:web:333246b49053015b6595e5",
  measurementId: "G-LN7JP3Z9DJ",
};

export const HEADER = {
  MOBILE_HEIGHT: 72,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const defaultSettings: SettingsValueProps = {
  themeMode: "light",
  themeDirection: "ltr",
  themeColorPresets: "default",
  themeLayout: "horizontal",
  themeStretch: false,
};
