import { ElementType, Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../layouts/dashboard";

import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";

const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        {
          path: "login",
          element: <LoginPage />,
        },
      ],
    },
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { element: <GeneralApp />, index: true },
        {
          path: "article",
          children: [
            { element: <Navigate to="/article/products" replace />, index: true },
            { path: "products", element: <ArticleProducts /> },
            { path: "product/:id", element: <ArticleProduct /> },
          ],
        },
        {
          path: "blog",
          children: [
            { element: <Navigate to="/blog/posts" replace />, index: true },
            { path: "posts", element: <BlogPosts /> },
            { path: "post/:id", element: <BlogPost /> },
            {
              path: "new",
              element: (
                <AuthGuard>
                  <BlogNewPost />
                </AuthGuard>
              ),
            },
            {
              path: "post/:id/edit",
              element: (
                <AuthGuard>
                  <BlogEditPost />
                </AuthGuard>
              ),
            },
          ],
        },
      ],
    },

    { path: "*", element: <Navigate to="/" replace /> },
  ]);
}

// AUTH
const LoginPage = Loadable(lazy(() => import("../pages/auth/Login")));

// General
const GeneralApp = Loadable(lazy(() => import("../pages/dashboard/general/App")));

// ARTICLE
const ArticleProducts = Loadable(lazy(() => import("../pages/dashboard/article/Products")));
const ArticleProduct = Loadable(lazy(() => import("../pages/dashboard/article/Product")));

// BLOG
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/blog/Posts")));
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/blog/Post")));
const BlogNewPost = Loadable(lazy(() => import("../pages/dashboard/blog/NewPost")));
const BlogEditPost = Loadable(lazy(() => import("../pages/dashboard/blog/EditPost")));
