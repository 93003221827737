import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ReactNode, useMemo, useRef } from "react";
import ReactQuill, { ReactQuillProps } from "react-quill";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../contexts/FirebaseContext";
import EditorToolbar, { formats, redoChange, undoChange } from "./EditorToolbar";

const RootStyle = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  "& .ql-container.ql-snow": {
    borderColor: "transparent",
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  "& .ql-editor": {
    minHeight: 200,
    "&.ql-blank::before": {
      fontStyle: "normal",
      color: theme.palette.text.disabled,
    },
    "& pre.ql-syntax": {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
}));

export interface Props extends ReactQuillProps {
  id?: string;
  error?: boolean;
  simple?: boolean;
  helperText?: ReactNode;
  sx?: BoxProps;
}

export default function Editor({
  id = "minimal-quill",
  error,
  value,
  onChange,
  simple = false,
  helperText,
  sx,
  ...other
}: Props) {
  const quillRef = useRef<ReactQuill>(null);

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: `#${id}`,
        handlers: {
          undo: undoChange,
          redo: redoChange,
          image: () => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.click();

            input.addEventListener("change", async () => {
              const editor = quillRef?.current?.getEditor();
              const file = input?.files?.[0];
              const range = editor?.getSelection(true);

              try {
                if (!editor || !file || !range) return;
                const storageRef = ref(storage, `images/${file.name} ${uuidv4()}`);
                await uploadBytes(storageRef, file).then((snapshot) => {
                  getDownloadURL(snapshot.ref).then((url) => {
                    console.log(url);
                    editor.insertEmbed(range.index, "image", url);
                    // @ts-ignore
                    editor.setSelection(range.index + 1);
                  });
                });
              } catch (error) {
                console.log(error);
              }
            });
          },
        },
      },
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      syntax: true,
      clipboard: {
        matchVisual: false,
      },
    };
  }, []);

  return (
    <div>
      <RootStyle
        sx={{
          ...(error && {
            border: (theme) => `solid 1px ${theme.palette.error.main}`,
          }),
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />
        <ReactQuill
          ref={quillRef}
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write something awesome..."
          {...other}
        />
      </RootStyle>

      {helperText && helperText}
    </div>
  );
}
