import { Box, styled } from "@mui/material";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import { HEADER } from "../../config";
import useResponsive from "../../hooks/useResponsive";
import DashboardFooter from "./footer";
import DashboardHeader from "./header";
import NavbarVertical from "./navbar/NavbarVertical";

const MainStyle = styled("main")(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up("lg")]: {
    paddingTop: HEADER.MAIN_DESKTOP_HEIGHT + 24,
  },
}));

export default function DashboardLayout() {
  const isDesktop = useResponsive("up", "lg");

  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader onOpenSidebar={() => setOpen(true)} />

      {!isDesktop && <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />}

      <MainStyle>
        <Outlet />
      </MainStyle>
      <DashboardFooter />
    </Box>
  );
}
