// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  // register: path(ROOTS_AUTH, '/register'),
  // loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  // registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  // verify: path(ROOTS_AUTH, '/verify'),
  // resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

// export const PATH_PAGE = {
// newPost: "/new",
// comingSoon: '/coming-soon',
// maintenance: '/maintenance',
// pricing: '/pricing',
// payment: '/payment',
// about: '/about-us',
// contact: '/contact-us',
// faqs: '/faqs',
// page404: '/404',
// page500: '/500',
// components: '/components',
// };

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: "/app",
  },
  article: {
    root: "/article",
    products: "/article/products",
    product: (id: number) => `/article/product/${id}`,
  },
  blog: {
    root: "/blog",
    posts: "/blog/posts",
    new: "/blog/new",
    post: (id: string) => `/blog/post/${id}`,
    edit: (id: string) => `/blog/post/${id}/edit`,
  },
};
