import { Box, Container, Divider, Typography } from "@mui/material";

const DashboardFooter = () => {
  return (
    <Box
      sx={{
        background: "#3c3c3c",
        justifyContent: "center",
        display: "flex",
        py: 5,
      }}
    >
      <Container sx={{ flex: 1 }} maxWidth="xl">
        <Typography variant="h4" color="#a1a1a1">
          (주)에이치에스큐
        </Typography>
        <Divider sx={{ background: "#707070", my: 3 }} />
        <Typography variant="subtitle1" color="#cecece">
          회사명 : (주)에이치에스큐 | 대표자 : 김형선 | 주소 : 경기도 안산시 단원구 능안로 81
          안산디지털파크 (지하)B101호
        </Typography>
        <Typography variant="subtitle1" color="#cecece">
          Phone : 031-310-8444 | FAX : 031-310-8445 | 사업자 등록번호 : 780-31-00828 | E-mail :
          sunkh33@naver.com
        </Typography>
      </Container>
    </Box>
  );
};

export default DashboardFooter;
