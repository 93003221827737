import { LoadingButton } from "@mui/lab";
import { Alert, Card, Container, IconButton, InputAdornment, Stack } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Iconify from "../../components/Iconify";
import Page from "../../components/Page";
import { FormProvider, RHFTextField } from "../../components/hook-form";
import useAuth from "../../hooks/useAuth";
import useIsMountedRef from "../../hooks/useIsMountedRef";
import { PATH_DASHBOARD } from "../../routes/paths";

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

const Login = () => {
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.email, data.password);
      navigate(PATH_DASHBOARD.blog.new);
    } catch (error: any) {
      console.error(error);
      reset();

      if (isMountedRef.current) {
        setError("afterSubmit", { ...error, message: error.message });
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Page title="로그인">
        <Container
          maxWidth="sm"
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

              <RHFTextField name="email" label="이메일" />

              <RHFTextField
                name="password"
                label="비밀번호"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                로그인
              </LoadingButton>
            </Stack>
          </Card>
        </Container>
      </Page>
    </FormProvider>
  );
};

export default Login;
