import { ReactNode, forwardRef } from "react";
// form
import { FormProvider as Form, UseFormReturn } from "react-hook-form";

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
  methods: UseFormReturn<any>;
  onSubmit?: VoidFunction;
};

const FormProvider = forwardRef(({ children, onSubmit, methods }: Props, ref: any) => {
  return (
    <Form {...methods}>
      <form ref={ref} onSubmit={onSubmit}>
        {children}
      </form>
    </Form>
  );
});

export default FormProvider;
